.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: hsl(233, 100%, 26%);
  border-color: hsl(233, 100%, 26%);
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #d2d2d2;
  padding: 3px 9px;
  outline: none;
  cursor: pointer;
  margin-left: 2px;
  margin-right: 2px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: hsl(233, 100%, 26%);
  border-color: hsl(233, 100%, 26%);
  outline: none;
  font-weight: 800;
}
.pagination > li > a,
.pagination > li > span {
  color: #121212;
  font-weight: 500;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
